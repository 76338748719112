<template>
  <div>
    <slot name="filters"></slot>

    <section class="shop-landing__products">
      <!-- <PageTitle :isAlone="true">
        <p>Loading...</p>
      </PageTitle> -->
      <template v-if="isLoading">
        <div class="row">
          <div
            class="column small-6 large-4 xxlarge-3"
            v-for="i in loadingToShow"
            :key="i">
            <LoadingCard />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="firstProductBatch.length" class="row">
          <LandingProduct
            v-for="(product, i) in firstProductBatch"
            :key="product._meta.uid + '-' + i"
            :product="product"
            :title="title" />

          <div
            v-if="callout"
            class="column small-12"
            :class="isWideCallout ? 'large-8 xxlarge-6' : 'small-12 large-3'">
            <ProductCallout
              v-if="callout === 'Product'"
              :product="calloutProduct"
              :modalTitle="title" />
            <ArticleCallout
              v-if="callout === 'Article'"
              :article="calloutArticle" />
            <Callout
              v-if="callout == 'Quiz' || callout == 'Questions'"
              :type="callout" />
          </div>

          <template v-if="secondProductBatch.length">
            <LandingProduct
              v-for="(product, i) in secondProductBatch"
              :key="product._meta.uid + '-' + i + '-' + 2"
              :product="product" />
          </template>

          <div id="load-more-button" v-if="hasMoreProducts" class="load-more column small-12">
            <LoadMoreButton @click="$emit('loadMore')" :isShowLess="false" />
          </div>
        </div>
        <!--
        <PageTitle v-else :isAlone="true">
          <p>Sorry, there are no products here yet!</p>
        </PageTitle>
        -->
      </template>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LandingProducts',

  components: {
    SvgIcon: () => import('@/components/global/SvgIcon'),
    ProductCard: () => import('@/components/shop/ProductCard'),
    LandingProduct: () =>
      import('@/components/pages/shop-landing/LandingProduct'),
    LoadMoreButton: () => import('@/components/global/LoadMoreButton'),
    AutoWidthSelect: () => import('@/components/global/AutoWidthSelect'),
    Tooltip: () => import('@/components/global/Tooltip'),
    Callout: () => import('@/components/pages/shop-landing/Callout'),
    ProductCallout: () =>
      import('@/components/pages/shop-landing/ProductCallout'),
    ArticleCallout: () =>
      import('@/components/pages/shop-landing/ArticleCallout'),
    PageTitle: () => import('@/components/global/PageTitle'),
    LoadingCard: () => import('@/components/global/LoadingCard'),
  },

  props: {
    hasMoreProducts: {
      type: Boolean,
      default: false,
    },

    callout: {
      type: String,
      default: null,
    },

    calloutArticle: {
      type: Object,
      default: null,
    },

    calloutProduct: {
      type: Object,
      default: null,
    },

    isWideCallout: {
      type: Boolean,
      default: false,
    },

    numFirstProducts: {
      type: Number,
      default: 0
    },

    products: {
      type: Array,
      default: () => [],
    },

    activeFilters: {
      type: Object,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    firstProductBatch() {
      return this.products
        .slice(0, this.numFirstProducts)
        .map(prod => (prod.node ? prod.node : prod))
    },

    secondProductBatch() {
      return this.products
        .slice(this.numFirstProducts)
        .map(prod => (prod.node ? prod.node : prod))
    },

    loadingToShow() {
      if (process.client) {
        const w =
          window.innerWith ||
          document.documentElement.clientWidth ||
          document.body.clientWidth

        return 480 < w && w < 1200 ? 3 : 4
      }
    },

  },

  watch: {
    selections: {
      handler(newVal) {
        this.$emit('change', newVal)
      },
      deep: true,
    },
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      if(!this.isLoading) {
        var element = document.getElementById("load-more-button");
        if(element){
          if (window.innerHeight - element.getBoundingClientRect().bottom > 200) {
            this.$emit('loadMore')
          }
        }
      }
    }
  }
}
</script>
