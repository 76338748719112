<template>
  <section v-if="slides && slides.length" class="home__hero">
    <VueSlickCarousel ref="carousel" v-bind="sliderSettings">
      <div v-for="slide in slides" class="home__hero__slide">
        <Link :link="slide.button_link">
          <PrismicLazyImage
            v-if="slide.background_image"
            :image="slide.background_image"
            width="1500"
            height="1000"
            class="cover medium-up"
            :disableLazy="true"
          />
          <PrismicLazyImage
            v-if="slide.background_image"
            :image="slide.mobile_background_image ? slide.mobile_background_image : slide.background_image"
            width="768"
            height="768"
            class="cover medium-down"
            :disableLazy="true"
          />
        </Link>
        <div class="home__hero__content">
          <div class="row">
            <div class="column">
              <div class="home__hero__content__inner">
                <h2 class="h5">{{ slide.title | getTitle }}</h2>
                <h3 class="h1" v-if="slide.subtitle">
                  <prismic-rich-text :field="slide.subtitle" />
                </h3>
                <prismic-rich-text v-if="slide.description" :field="slide.description" />
                <Button v-if="slide.button_link && slide.button_text" :link="slide.button_link" :text="slide.button_text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import Link from '@/components/global/Link';
  import Button from '@/components/global/Button';

  export default {
    name: 'Gallery',

    components: {
      VueSlickCarousel,
      PrismicLazyImage,
      Link,
      Button
    },

    props: {
      slides: {
        type: Array,
        default: null
      }
    },

    data() {
      return {
        sliderSettings: {
          autoplay: true,
          slidesToShow: 1,
          autoplaySpeed: 5000,
          fade: true,
          dots: true,
          arrows: false,
          pauseOnHover: false
        }
      }
    },

    methods: {
      pauseSlider() {
        var carousel = this.$refs.carousel;
        if(carousel) {
          carousel.pause();
        }
      }
    },

    mounted() {
      let dots = document.querySelector('.home__hero .slick-dots');

      dots.addEventListener('click', (event) => {
      	this.pauseSlider();
      });
    }
  }
</script>
